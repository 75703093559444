import React, { useState } from 'react'

export default function Nav() {
   const [smoll,setSmoll]=useState(false)
  function handleScreen(){
setSmoll(!smoll);
   }
  return (
    <div className=' z-50 opacity-80 bg-Cbblack shadow-md h-[50px] fixed w-full'>
    <div className='mt-3  text-white text-sm md:text-lg flex items-center justify-between ml-4 mr-4 bg-opacity-100 cursor-pointer'>
        <p className='ml-20 mr-20'>Amuecsf</p>
        <ul className='hidden md:flex md:justify-end md:gap-8 ml-20 mr-20'>
           <li>OurCity</li> 
           <li>FellowShip</li>
           <li>Program</li>
           <li>Register</li>
           <li>Gallery</li>
           <li>Leaders</li>
        </ul>
        <button className='block md:hidden py-1  px-1 mx-2 rounded focus:outline-none hover:bg-green-200  ' onClick={handleScreen}>
          <div className='w-5 h-1 bg-white mb-1'></div>
          <div className='w-5 h-1 bg-white mb-1'></div>
          <div className='w-5 h-1 bg-white mb-1'></div> 
        </button>
        {smoll===true? 
          <div className='block md:hidden absolute top-0  left-0 h-screen w-6/12 bg-Cbblack  opacity-90   transform transition-all duration-300'>
<ul className='flex flex-col items-center w-full text-base cursor-pointer text-black mt-5 pt-10'>
<li className='hover:bg-gray-500 hover:text-white py-4 px-6 w-full'><a href="Register">Register</a></li>
<li className='hover:bg-gray-500 hover:text-white py-4 px-6 w-full'>Gallery</li>
<li className='hover:bg-gray-500 hover:text-white py-4 px-6 w-full'>Contact</li>
</ul>
          </div>
:''}
    </div>
    </div>
  )
}
